import axios, { AxiosError } from "axios";
import { apiQuery } from "./baseurl";
import { ExceptionService, ToastService } from "../services";

const authQuery = axios.create({
  baseURL: apiQuery,
});

authQuery.interceptors.request.use((config) => config);

authQuery.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    ToastService.onDanger({
      title: ExceptionService.errorResolver(error.response?.data),
    });

    return Promise.reject(error);
  }
);

const baseQuery = axios.create({
  baseURL: apiQuery,
});

baseQuery.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    ToastService.onDanger({
      title: ExceptionService.errorResolver(error.response?.data),
    });

    return Promise.reject(error);
  }
);

export { authQuery, baseQuery };
