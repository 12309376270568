import { CallsAnalysis } from "@/shared";
import dayjs from "dayjs";
import { fieldLabels, Months, Weeks } from "../models";
import { FormControlLabel, Radio, styled } from "@mui/material";
import { Colors } from "@/styles";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export interface TableField {
  value: string;
  title: string;
}

export const fields: TableField[] = [
  { value: "total_calls", title: "Дзвінків загалом" },
  { value: "total_calls_in", title: "Вхідних загалом" },
  { value: "total_calls_out", title: "Вихідних загалом" },

  { value: "in_first", title: "Первинних вхідних" },
  { value: "in_second", title: "Повторних вхідних" },

  { value: "answered", title: "Вдалих загалом" },
  { value: "answered_in", title: "Вдалих вхідних" },
  { value: "answered_in_first", title: "Вдалих первинних вхідних" },
  { value: "answered_in_second", title: "Вдалих повторних вхідних" },
  { value: "answered_out", title: "Вдалих вихідних" },

  { value: "not_answered", title: "Помилкових загалом" },
  { value: "not_answered_in", title: "Помилкових вхідних" },
  { value: "not_answered_in_first", title: "Помилкових первинних вхідних" },
  { value: "not_answered_in_second", title: "Помилкових вторинних вхідних" },
  { value: "not_answered_out", title: "Помилкових вихідних" },

  { value: "call_tracking", title: "Колтрекінг" },
  { value: "regular_call", title: "Звичайні" },
];

export const defaultRowsArray = [
  { id: "total_calls" },
  { id: "total_calls_in" },
  { id: "total_calls_out" },

  { id: "in_first" },
  { id: "in_second" },

  { id: "answered" },
  { id: "answered_in" },
  { id: "answered_in_first" },
  { id: "answered_in_second" },
  { id: "answered_out" },

  { id: "not_answered" },
  { id: "not_answered_in" },
  { id: "not_answered_in_first" },
  { id: "not_answered_in_second" },
  { id: "not_answered_out" },

  { id: "call_tracking" },
  { id: "regular_call" },
];

export const colors = [
  "#8884d8", // Light Purple
  "#82ca9d", // Light Green
  "#ffc658", // Light Yellow
  "#ff7300", // Orange
  "#387908", // Dark Green
  "#001f3f", // Navy Blue
  "#ff4136", // Red
  "#2ecc40", // Green
  "#0074d9", // Blue
  "#b10dc9", // Purple
  "#f012be", // Magenta
  "#39cccc", // Teal
  "#85144b", // Maroon
  "#ff851b", // Light Orange
  "#7fdbff", // Sky Blue
  "#3d9970", // Olive
  "#111111", // Black
];

export const isDate = (dateStr: string) => {
  return (
    dayjs(dateStr, "YYYY-MM-DD", true).isValid() ||
    dayjs(dateStr, "YYYY-MM-DDTHH:mm:ss", true).isValid()
  );
};

export const groupByWeek = (
  data: CallsAnalysis[],
  selectedFields: string[]
) => {
  const weeks: Weeks = {};
  if (data.length) {
    let currentStartDate = dayjs(data[0]?.date).startOf("week");

    const finalEndDate = dayjs(data[data?.length - 1]?.date).endOf("week");

    while (currentStartDate.isBefore(finalEndDate)) {
      const weekStart = currentStartDate.format("YYYY-MM-DD");
      weeks[weekStart] = {
        start_date: weekStart,
        end_date: currentStartDate.endOf("week").format("YYYY-MM-DD"),
        date: weekStart,
      };
      selectedFields.forEach((field) => {
        weeks[weekStart][field] = 0;
      });
      currentStartDate = currentStartDate.add(1, "week");
    }

    data.forEach((item) => {
      const itemDate = dayjs(item.date);
      const weekStart = itemDate.startOf("week").format("YYYY-MM-DD");

      if (weeks[weekStart]) {
        selectedFields.forEach((field) => {
          if (item.hasOwnProperty(field)) {
            // @ts-ignore
            weeks[weekStart][field] += item[field] || 0;
          }
        });
      }
    });
  }

  return Object.values(weeks);
};

export const groupByMonth = (
  data: CallsAnalysis[],
  selectedFields: string[]
) => {
  const months: Months = {};

  if (data.length) {
    let currentStartDate = dayjs(data[0]?.date).startOf("month");
    const finalEndDate = dayjs(data[data?.length - 1]?.date).endOf("month");

    while (currentStartDate.isBefore(finalEndDate)) {
      const monthStart = currentStartDate.format("YYYY-MM-DD");
      const monthName = currentStartDate.format("MMMM YYYY");
      months[monthStart] = {
        start_date: monthStart,
        end_date: currentStartDate.endOf("month").format("YYYY-MM-DD"),
        date: monthName,
      };
      selectedFields.forEach((field) => {
        // @ts-ignore
        months[monthStart][field] = 0;
      });
      currentStartDate = currentStartDate.add(1, "month");
    }

    data.forEach((item) => {
      const itemDate = dayjs(item.date);
      const monthStart = itemDate.startOf("month").format("YYYY-MM-DD");

      if (months[monthStart]) {
        selectedFields.forEach((field) => {
          //@ts-ignore
          months[monthStart][field] += item[field as keyof CallsAnalysis] || 0;
        });
      }
    });
  }

  return Object.values(months);
};

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    fontSize: "14px",
    marginRight: "10px",
  },
}));

export const StyledRadio = styled(Radio)(({ theme }) => ({
  color: Colors.Primary,
  width: "14px",
  height: "14px",
  marginRight: "6px",
  "&.Mui-checked": {
    color: Colors.Primary,
  },
}));

export const handleExportToExcel = async (
  calls: CallsAnalysis[],
  dateFrom: dayjs.Dayjs | null,
  dateTo: dayjs.Dayjs | null,
  selectedFields: string[],
  chartImage: string
) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Call Analysis");

  const dates = calls.map((call) => call.date);
  worksheet.columns = [
    { header: "Результат", key: "result", width: 25 },
    ...dates.map((date) => ({
      header: isDate(date) ? dayjs(date).format("DD/MM/YYYY") : date,
      key: date,
      width: 13,
    })),
  ];

  const headerRow = worksheet.getRow(1);
  headerRow.height = 20;
  headerRow.eachCell((cell) => {
    cell.alignment = { horizontal: "left" };
  });

  selectedFields.forEach((field) => {
    const label = fieldLabels[field] || field;
    const row = { result: label };

    calls.forEach((call) => {
      const dateKey = call.date as string;
      // @ts-ignore
      row[dateKey] = call[field] || 0;
    });

    const addedRow = worksheet.addRow(row);

    addedRow.height = 20;

    addedRow.eachCell((cell) => {
      cell.alignment = { horizontal: "left" };
    });
  });

  if (chartImage) {
    const imageId = workbook.addImage({
      base64: chartImage,
      extension: "png",
    });

    worksheet.addImage(imageId, {
      tl: { col: 0, row: worksheet.rowCount + 2 },
      ext: { width: 1000, height: 450 },
    });
  }

  const buffer = await workbook.xlsx.writeBuffer();
  const filename = `Аналіз дзвінків з ${dayjs(dateFrom?.toDate()).format(
    "DD-MM-YYYY"
  )} по ${dayjs(dateTo?.toDate()).format("DD-MM-YYYY")}.xlsx`;

  saveAs(new Blob([buffer]), filename);
};
