import { Colors } from "@/styles";
import styled from "@emotion/styled";
import { TextField } from "@mui/material";

export const CustomInput = styled(TextField)({
  "& label.Mui-focused": {
    color: Colors.Primary,
    fontFamily: "Roboto, Arial, sans-serif",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: Colors.Primary,
    },
    "&.Mui-focused fieldset": {
      borderColor: Colors.Primary,
    },
    "& input": {
      fontFamily: "Roboto, Arial, sans-serif",
    },
  },
});
