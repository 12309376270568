import { IconButton, Tooltip } from "@mui/material";
import { FcPrint, FcDownload, FcDocument } from "react-icons/fc";
import styles from "../CallsAnalysis.module.scss";

interface ActionButtonsProps {
  handleExportToExcel: () => void;
  handleExportToPDF: () => Promise<void>;
}

export const ActionButtons = ({
  handleExportToExcel,
  handleExportToPDF,
}: ActionButtonsProps) => {
  return (
    <div className={styles.action_buttons_container}>
      <div className={styles.action_buttons}>
        <Tooltip title="Зберегти">
          <IconButton onClick={handleExportToExcel}>
            <FcDownload fontSize={22} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Друк">
          <IconButton onClick={handleExportToPDF}>
            <FcPrint fontSize={22} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Попередній перегляд">
          <IconButton onClick={handleExportToPDF}>
            <FcDocument fontSize={22} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};
