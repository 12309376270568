// @ts-nocheck

import {
  CallsAnalysis,
  StyledDataGridCallAnalysis,
  useMenuDrawerStoreSelectors,
} from "@/shared";
import { Box, RadioGroup } from "@mui/material";
import { FcDeleteDatabase } from "react-icons/fc";
import {
  rows,
  StyledFormControlLabel,
  StyledRadio,
  tableColumns,
} from "../helpers";
import { CallAnalysisDataChart } from "./CallAnalysisDataChart";
import { ChangeEvent } from "react";

interface CallsAnalysisTableProps {
  calls: CallsAnalysis[];
  isLoading: boolean;
  selectedFields: string[];
  groupBy: string;
  handleGroupByChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onCaptureImage: (imageDataUrl: string) => void;
}

export const CallsAnalysisTable = ({
  calls,
  isLoading,
  selectedFields,
  groupBy,
  handleGroupByChange,
  onCaptureImage,
}: CallsAnalysisTableProps) => {
  const isMenuDrawerOpen = useMenuDrawerStoreSelectors.use.isMenuDrawerOpen();

  const width =
    window.innerWidth - "20%" - (isMenuDrawerOpen ? 240 + 24 : 64 + 26);

  return (
    <>
      <Box
        sx={{
          height: !calls.length
            ? "calc(100vh - 25px)"
            : selectedFields.length * 35 + 110,
          width: width,
          maxWidth: width,
          position: "relative",
        }}
      >
        <StyledDataGridCallAnalysis
          rows={rows(calls, selectedFields)}
          columns={tableColumns(calls)}
          getRowId={(row) => row.id}
          rowHeight={35}
          columnHeaderHeight={35}
          disableMultipleRowSelection
          hideFooterSelectedRowCount
          checkboxSelection
          disableColumnMenu
          hideFooterPagination
          loading={isLoading}
          slots={{
            noRowsOverlay: () => (
              <Box
                display="flex"
                height="100%"
                alignItems="center"
                justifyContent="center"
                fontWeight="500"
                fontSize={14}
                flexDirection="column"
              >
                <FcDeleteDatabase
                  fontSize={120}
                  style={{ marginBottom: "10px" }}
                />

                <Box>Дзвінків не зафіксовано.</Box>

                <Box>Будь ласка, виберіть іншу дату для аналізу дзвінків.</Box>
              </Box>
            ),
          }}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px 0",
            position: "absolute",
            bottom: 6,
            left: 20,
          }}
        >
          <RadioGroup row value={groupBy} onChange={handleGroupByChange}>
            <StyledFormControlLabel
              value="day"
              control={<StyledRadio size="small" />}
              label="По дням"
            />
            <StyledFormControlLabel
              value="week"
              control={<StyledRadio size="small" />}
              label="По тижням"
            />
            <StyledFormControlLabel
              value="month"
              control={<StyledRadio size="small" />}
              label="По місяцям"
            />
          </RadioGroup>
        </Box>
      </Box>
      {!!calls.length && (
        <CallAnalysisDataChart
          calls={calls}
          selectedFields={selectedFields}
          onCaptureImage={onCaptureImage}
        />
      )}
    </>
  );
};
