import { immer } from "zustand/middleware/immer";
import { AuthStore, State } from "./types";
import { PersistStorageKeys } from "../models";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const initialState: State = {
  user: null,
  user_id: null,
  counterparty: null,
  counterparty_id: null,
};

export const useAuthStore = create<AuthStore>()(
  persist(
    immer((set) => ({
      ...initialState,
      setUserName: (user_name: string | null) =>
        set((state) => {
          state.user = user_name;
        }),
      setUserId: (user_id: string | null) =>
        set((state) => {
          state.user_id = user_id;
        }),
      serCounterpartyName: (counterparty_name: string | null) =>
        set((state) => {
          state.counterparty = counterparty_name;
        }),
      serCounterpartyId: (counterparty_id: string | null) =>
        set((state) => {
          state.counterparty_id = counterparty_id;
        }),
      logout: () =>
        set((state) => {
          state.user = null;
          state.user_id = null;
          state.counterparty = null;
          state.counterparty_id = null;
        }),
    })),
    {
      name: PersistStorageKeys.AUTH,
      storage: createJSONStorage(() => localStorage),
    }
  )
);
