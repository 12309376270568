import { DD_MM_YYYY, DD_MM_YYYY_TIME, MissedCall } from "@/shared";
import dayjs from "dayjs";
import * as XLSX from "xlsx";

export const handleExportToExcel = (
  calls: MissedCall[],
  dateFrom: dayjs.Dayjs | null,
  dateTo: dayjs.Dayjs | null
) => {
  const formattedCalls = calls.map((call) => ({
    "Абонент, тел":
      call.caller === call.caller_code
        ? call.caller
        : `${call.caller}, ${call.caller_code}`,
    "Тип розмови": call.call_type,
    Результат: call.call_result,
    Період: DD_MM_YYYY_TIME(call.date),
  }));

  const worksheet = XLSX.utils.json_to_sheet(formattedCalls);

  worksheet["!cols"] = [{ wch: 30 }, { wch: 17 }, { wch: 17 }, { wch: 30 }];

  worksheet["!rows"] = [
    { hpt: 25 },
    ...formattedCalls.map(() => ({ hpt: 25 })),
  ];

  const workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workbook, worksheet, "Missed Calls");

  XLSX.writeFile(
    workbook,
    `Дзвінки без відповіді за період з ${DD_MM_YYYY(
      dateFrom?.toDate()
    )} по ${DD_MM_YYYY(dateTo?.toDate())} .xlsx`
  );
};
