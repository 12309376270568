import { Colors } from "@/styles";
import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const PrimaryButton = styled(Button)({
  backgroundColor: Colors.Primary,
  color: "white",
  fontSize: "16px",
  "&:hover": {
    backgroundColor: Colors.Primary,
    opacity: 0.7,
  },
  "&.Mui-disabled": {
    backgroundColor: "lightgray",
    color: "gray",
  },
});
