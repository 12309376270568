// @ts-nocheck

import {
  MissedCall,
  StyledDataGrid,
  useMenuDrawerStoreSelectors,
} from "@/shared";
import { Box } from "@mui/material";
import { FcDeleteDatabase } from "react-icons/fc";
import { tableColumns } from "../helpers";
import { v4 as uuidv4 } from "uuid";

interface MissedCallsTableProps {
  calls: MissedCall[];
  boxHeight: number;
  isLoading: boolean;
}

export const MissedCallsTable = ({
  calls,
  boxHeight,
  isLoading,
}: MissedCallsTableProps) => {
  const isMenuDrawerOpen = useMenuDrawerStoreSelectors.use.isMenuDrawerOpen();

  const width =
    window.innerWidth - "20%" - (isMenuDrawerOpen ? 240 + 24 : 64 + 26);

  return (
    <Box
      sx={{
        height: "calc(100vh - 25px)",
        width: width,
        maxWidth: width,
        position: "relative",
      }}
    >
      <StyledDataGrid
        rows={calls}
        columns={tableColumns(calls)}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[100]}
        getRowId={() => uuidv4()}
        rowHeight={35}
        columnHeaderHeight={35}
        disableMultipleRowSelection
        hideFooterSelectedRowCount
        checkboxSelection
        disableColumnMenu
        loading={isLoading}
        slots={{
          noRowsOverlay: () => (
            <Box
              display="flex"
              height="100%"
              alignItems="center"
              justifyContent="center"
              fontWeight="500"
              fontSize={14}
              flexDirection="column"
            >
              <FcDeleteDatabase
                fontSize={120}
                style={{ marginBottom: "10px" }}
              />

              <Box>Пропущених дзвінків не зафіксовано.</Box>

              <Box>
                Будь ласка, виберіть іншу дату для перегляду пропущених
                дзвінків.
              </Box>
            </Box>
          ),
        }}
      />
    </Box>
  );
};
