import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { Colors } from "@/styles";
import { Box, IconButton } from "@mui/material";
import { CustomDatePicker } from "@/shared";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import styles from "../CallsAnalysis.module.scss";
import "dayjs/locale/uk";

interface DateFilterProps {
  dateFrom: Dayjs | null;
  setDateFrom: (date: Dayjs | null) => void;
  dateTo: Dayjs | null;
  setDateTo: (date: Dayjs | null) => void;
}

export const CallsAnalysisDateFilter = ({
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
}: DateFilterProps) => {
  dayjs.locale("uk");

  return (
    <Box sx={{ marginBottom: "6px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div
              className={styles.date_picker}
              style={{ justifyContent: "space-between" }}
            >
              <CustomDatePicker
                value={dateFrom}
                onChange={(newValue) => setDateFrom(newValue)}
                format="DD/MM/YYYY"
                slots={{
                  openPickerButton: (props) => (
                    <IconButton
                      {...props}
                      sx={{
                        backgroundColor: "transparent",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <CalendarMonthIcon sx={{ fontSize: "5px" }} />
                    </IconButton>
                  ),
                }}
                slotProps={{
                  day: {
                    sx: {
                      "&.MuiPickersDay-root.Mui-selected": {
                        backgroundColor: Colors.Primary,
                      },
                    },
                  },
                }}
              />
            </div>

            <div className={styles.date_picker}>
              <CustomDatePicker
                value={dateTo}
                onChange={(newValue) => setDateTo(newValue)}
                format="DD/MM/YYYY"
                slots={{
                  openPickerButton: (props) => (
                    <IconButton
                      {...props}
                      sx={{
                        backgroundColor: "transparent",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <CalendarMonthIcon sx={{ fontSize: "5px" }} />
                    </IconButton>
                  ),
                }}
                slotProps={{
                  day: {
                    sx: {
                      "&.MuiPickersDay-root.Mui-selected": {
                        backgroundColor: Colors.Primary,
                      },
                    },
                  },
                }}
              />
            </div>
          </LocalizationProvider>
        </Box>
      </Box>
    </Box>
  );
};
