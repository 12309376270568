import { MenuDrawer } from "@/shared";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

export const ProtectedLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/", { replace: true });
  }, []);

  return (
    <MenuDrawer>
      <Outlet />
    </MenuDrawer>
  );
};
