import { useMutation } from "@tanstack/react-query";
import { AuthService } from "../AuthService";
import { SignInRequest } from "../models";
import { useAuthStoreSelectors } from "@/shared/stores";

export const useSignIn = () => {
  const setUserName = useAuthStoreSelectors.use.setUserName();
  const setUserId = useAuthStoreSelectors.use.setUserId();
  const serCounterpartyName = useAuthStoreSelectors.use.serCounterpartyName();
  const serCounterpartyId = useAuthStoreSelectors.use.serCounterpartyId();

  const { isPending, mutateAsync: signIn } = useMutation({
    mutationFn: async (params: SignInRequest) => {
      const response = await AuthService.signIn(params);
      return response?.data;
    },
    onSuccess: (data) => {
      setUserName(data.user);
      setUserId(data.user_id);
      serCounterpartyName(data.counterparty);
      serCounterpartyId(data.counterparty_id);
    },
  });

  return { isPending, signIn };
};
