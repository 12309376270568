import { Colors } from "@/styles";
import { Box, Checkbox, FormControlLabel } from "@mui/material";

interface TechFieldsCheckboxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export const TechFieldsCheckbox = ({
  checked,
  onChange,
}: TechFieldsCheckboxProps) => {
  return (
    <Box sx={{ position: "fixed", right: 0, top: "35px" }}>
      <FormControlLabel
        control={
          <Checkbox
            defaultChecked
            size="small"
            checked={checked}
            onChange={(event) => onChange(event.target.checked)}
            sx={{
              color: Colors.Primary,
              "&.Mui-checked": {
                color: Colors.Primary,
              },
            }}
          />
        }
        label="Показати технічні поля"
        sx={{
          "& .MuiFormControlLabel-label": {
            fontSize: "13px",
          },
        }}
      />
    </Box>
  );
};
