import { Bounce, toast, ToastOptions } from "react-toastify";

interface ToastProps {
  title: string;
}

const toastParams: ToastOptions = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  transition: Bounce,
};

const onSuccess = ({ title }: ToastProps) => {
  toast.success(title, toastParams);
};

const onDanger = ({ title }: ToastProps) => {
  toast.error(title, toastParams);
};

const onWarning = ({ title }: ToastProps) => {
  toast.warn(title, toastParams);
};

export const ToastService = {
  onSuccess,
  onDanger,
  onWarning,
};
