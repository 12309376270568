import { ProtectedLayout } from "@/modules";
import {
  CallsAnalysisPage,
  CallsJournalPage,
  MissedCallsPage,
  SubscribersListPage,
} from "@/pages";
import { Navigate } from "react-router-dom";

export const privateRoutes = () => {
  return {
    element: <ProtectedLayout />,
    children: [
      { path: "/", element: <CallsJournalPage /> },
      { path: "/missed-calls", element: <MissedCallsPage /> },
      { path: "/calls-analysis", element: <CallsAnalysisPage /> },
      { path: "/subscribers-list", element: <SubscribersListPage /> },
      { path: "*", element: <Navigate to="/" replace /> },
    ],
  };
};
