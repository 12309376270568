import styles from "./CallsAnalysis.module.scss";
import {
  CallsAnalysisPdf,
  CallsAnalysisTable,
  SelectTableFields,
} from "./components";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import {
  ExceptionService,
  ToastService,
  useGetCallsAnalysis,
  useMenuDrawerStoreSelectors,
} from "@/shared";
import { groupByMonth, groupByWeek } from "./helpers";
import { pdf } from "@react-pdf/renderer";

export const CallsAnalysisPage = () => {
  const isMenuDrawerOpen = useMenuDrawerStoreSelectors.use.isMenuDrawerOpen();

  const [isLoading, setIsLoading] = useState(false);

  const [dateFrom, setDateFrom] = useState<Dayjs | null>(dayjs(new Date()));
  const [dateTo, setDateTo] = useState<Dayjs | null>(dayjs(new Date()));

  const [selectedFields, setSelectedFields] = useState<string[]>([
    "total_calls",
    "total_calls_in",
    "total_calls_out",
    "in_first",
    "answered_out",
    "not_answered",
  ]);

  const [groupBy, setGroupBy] = useState("day");

  const [chartImage, setChartImage] = useState("");

  const onCaptureImage = (imgUrl: string) => {
    setChartImage(imgUrl);
  };

  const handleGroupByChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGroupBy(event.target.value);
  };

  const filterDataBySelectedFields = (data: any[], fields: string[]) => {
    return data.map((item) => {
      const filteredItem: any = {
        date: item.date,
      };
      fields.forEach((field) => {
        if (item.hasOwnProperty(field)) {
          filteredItem[field] = item[field];
        }
      });
      return filteredItem;
    });
  };

  const { calls, refetch } = useGetCallsAnalysis({
    date_start: dateFrom?.format("YYYY-MM-DD") || "",
    date_end: dateTo?.format("YYYY-MM-DD") || "",
  });

  const refetchCalls = async () => {
    try {
      setIsLoading(true);
      await refetch();
    } catch (error) {
      ToastService.onDanger({ title: ExceptionService.errorResolver(error) });
    } finally {
      setIsLoading(false);
    }
  };

  const filteredCalls = filterDataBySelectedFields(calls, selectedFields);

  const filteredByWeek = groupByWeek(filteredCalls, selectedFields);

  const filterByMonth = groupByMonth(filteredCalls, selectedFields);

  const sideContainerRef = useRef<HTMLDivElement | null>(null);
  const [mainContainerWidth, setMainContainerWidth] = useState("100vw");

  useEffect(() => {
    const updateMainContainerWidth = () => {
      const sideContainerWidth = sideContainerRef.current
        ? sideContainerRef.current.offsetWidth
        : 0;
      const drawerWidth = isMenuDrawerOpen ? 240 + 12 : 64 + 14;
      setMainContainerWidth(
        `calc(100vw - ${sideContainerWidth + drawerWidth}px)`
      );
    };

    updateMainContainerWidth();

    window.addEventListener("resize", updateMainContainerWidth);
    return () => window.removeEventListener("resize", updateMainContainerWidth);
  }, [isMenuDrawerOpen]);

  const handleOpenPdfInNewTab = async () => {
    const blob = await pdf(
      <CallsAnalysisPdf
        calls={
          groupBy === "day"
            ? filteredCalls
            : groupBy === "week"
            ? filteredByWeek
            : filterByMonth
        }
        dateFrom={dateFrom}
        dateTo={dateTo}
        chartImage={chartImage}
        selectedFields={selectedFields}
      />
    ).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
      <div className={styles.side_container} ref={sideContainerRef}>
        <div className={styles.filter}>
          <SelectTableFields
            dateFrom={dateFrom}
            setDateFrom={setDateFrom}
            dateTo={dateTo}
            setDateTo={setDateTo}
            refetchCalls={refetchCalls}
            isLoading={isLoading}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            calls={
              groupBy === "day"
                ? filteredCalls
                : groupBy === "week"
                ? filteredByWeek
                : filterByMonth
            }
            chartImage={chartImage}
            handleOpenPdfInNewTab={handleOpenPdfInNewTab}
          />
        </div>
      </div>

      <div
        className={styles.main_container}
        style={{
          width: mainContainerWidth,
        }}
      >
        <CallsAnalysisTable
          //@ts-ignore
          calls={
            groupBy === "day"
              ? filteredCalls
              : groupBy === "week"
              ? filteredByWeek
              : filterByMonth
          }
          isLoading={isLoading}
          selectedFields={selectedFields}
          groupBy={groupBy}
          handleGroupByChange={handleGroupByChange}
          onCaptureImage={onCaptureImage}
        />
      </div>
    </div>
  );
};
