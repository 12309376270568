// @ts-nocheck

import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { ListItemText } from "@mui/material";
import { match } from "ts-pattern";
import {
  PiPhoneXLight,
  PiShareNetworkLight,
  PiStackLight,
} from "react-icons/pi";
import { PiUserListLight } from "react-icons/pi";
import { IoIosLogOut } from "react-icons/io";

export const drawerWidth = 240;

export const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  borderTopRightRadius: 10,
  borderTopLeftRadius: 10,
  borderColor: "transparent",
  boxShadow: "0px 0px 10px 2px #3f3f4026",
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  borderTopRightRadius: 10,
  borderBottomRightRadius: 10,
  borderColor: "transparent",
  boxShadow: "0px 0px 10px 2px #3f3f4026",
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export const MenuItem = styled(ListItemText)(() => ({
  "& .MuiTypography-root": {
    fontSize: "14px",
  },
}));

export const menuIcon = (index: number) =>
  match({ index })
    .with({ index: 0 }, () => <PiStackLight />)
    .with({ index: 1 }, () => <PiPhoneXLight />)
    .with({ index: 2 }, () => <PiShareNetworkLight />)
    .with({ index: 3 }, () => <PiUserListLight />)
    .with({ index: 4 }, () => <IoIosLogOut />)
    .otherwise(() => null);
