import { DD_MM_YYYY_TIME, MissedCall } from "@/shared";
import { GridColDef } from "@mui/x-data-grid";

export const tableColumns = (calls: MissedCall[]) => {
  const columns: GridColDef<(typeof calls)[number]>[] = [
    {
      field: "caller",
      headerName: "Абонент, тел",
      sortable: false,
      width: 320,
      renderCell: ({ row }) =>
        row.caller === row.caller_code
          ? row.caller
          : `${row.caller}, ${row.caller_code}`,
    },
    {
      field: "call_type",
      headerName: "Тип розмови",
      sortable: false,
      width: 140,
    },
    {
      field: "call_result",
      sortable: false,
      headerName: "Результат",
      width: 140,
    },
    {
      field: "date",
      sortable: false,
      headerName: "Період",
      width: 230,
      renderCell: ({ row }) => DD_MM_YYYY_TIME(row.date),
    },
  ];

  return columns;
};
