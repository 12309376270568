import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import { CallsAnalysis, DD_MM_YYYY } from "@/shared";
import dayjs from "dayjs";
import { isDate } from "../helpers";
import { fieldLabels } from "../models";

Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
});

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontFamily: "Roboto",
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    fontWeight: 500,
  },
  table: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#b3b3b3",
    borderRadius: 4,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeaderRow: {
    flexDirection: "row",
    backgroundColor: "#f0f0f0",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 0.25,
    borderColor: "#b3b3b3",
    padding: 5,
  },
  tableCell: {
    fontSize: 10,
    padding: 2,
  },
  tableHeaderCell: {
    fontSize: 10,
    fontWeight: 500,
    padding: 2,
  },
  oddRow: {
    backgroundColor: "#f5f5f5",
  },
  evenRow: {
    backgroundColor: "#ffffff",
  },
  chartImage: {
    marginTop: 20,
    width: "100%",
  },
});

interface CallsAnalysisPdfDocumentProps {
  calls: CallsAnalysis[];
  dateFrom: dayjs.Dayjs | null;
  dateTo: dayjs.Dayjs | null;
  chartImage: string;
  selectedFields: string[];
}

export const CallsAnalysisPdf = ({
  calls,
  dateFrom,
  dateTo,
  chartImage,
  selectedFields,
}: CallsAnalysisPdfDocumentProps) => {
  const chunkArray = (array: CallsAnalysis[], chunkSize: number) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const maxRowsPerPage = 7;
  const chunkedData = chunkArray(calls, maxRowsPerPage);

  return (
    <Document>
      {chunkedData.map((chunk, pageIndex) => (
        <Page style={styles.page} key={`page-${pageIndex}`}>
          {pageIndex === 0 && (
            <Text style={styles.header}>
              Звіт про дзвінки за період з {DD_MM_YYYY(dateFrom?.toDate())} по{" "}
              {DD_MM_YYYY(dateTo?.toDate())}
            </Text>
          )}

          <View style={styles.table}>
            <View style={styles.tableHeaderRow}>
              <View style={[styles.tableCol, { width: "15%" }]}>
                <Text style={styles.tableHeaderCell}>Результат</Text>
              </View>
              {chunk.map((data, index) => (
                <View
                  style={[styles.tableCol, { width: "12%" }]}
                  key={`header-${index}`}
                >
                  <Text style={styles.tableHeaderCell}>
                    {isDate(data.date)
                      ? dayjs(data.date).format("DD/MM/YYYY")
                      : data.date}
                  </Text>
                </View>
              ))}
            </View>

            {selectedFields.map((fieldKey, rowIndex) => (
              <View
                key={`row-${rowIndex}`}
                style={[
                  styles.tableRow,
                  rowIndex % 2 === 0 ? styles.evenRow : styles.oddRow,
                ]}
              >
                <View style={[styles.tableCol, { width: "15%" }]}>
                  <Text style={styles.tableCell}>{fieldLabels[fieldKey]}</Text>
                </View>
                {chunk.map((row, colIndex) => (
                  <View
                    key={`data-${rowIndex}-${colIndex}`}
                    style={[styles.tableCol, { width: "12%" }]}
                  >
                    {/* @ts-ignore */}
                    <Text style={styles.tableCell}>{row[fieldKey]}</Text>
                  </View>
                ))}
              </View>
            ))}
          </View>

          {pageIndex === 0 && (
            <Image style={styles.chartImage} src={chartImage} />
          )}
        </Page>
      ))}
    </Document>
  );
};
