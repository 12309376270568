import { Box } from "@mui/material";
import { Dayjs } from "dayjs";
import { SecondaryButton } from "@/shared";
import styles from "../MissedCalls.module.scss";
import { MissedCallsDateFilter } from "./MissedCallsDateFilter";
import { Colors } from "@/styles";

interface SelectedTableFieldsProps {
  dateFrom: Dayjs | null;
  setDateFrom: (date: Dayjs | null) => void;
  dateTo: Dayjs | null;
  setDateTo: (date: Dayjs | null) => void;
  refetchCalls: () => void;
  isLoading: boolean;
}

export const ActionSide = ({
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
  refetchCalls,
  isLoading,
}: SelectedTableFieldsProps) => {
  return (
    <Box sx={{ mb: "16px" }}>
      <Box
        sx={{
          fontSize: "13px",
          color: Colors.Green,
          fontWeight: "500",
          marginBottom: "10px",
        }}
      >
        Фільтр звіту
      </Box>

      <MissedCallsDateFilter
        dateFrom={dateFrom}
        setDateFrom={setDateFrom}
        dateTo={dateTo}
        setDateTo={setDateTo}
      />

      <SecondaryButton
        className={styles.confirm_button}
        onClick={refetchCalls}
        disabled={isLoading}
      >
        Сформувати
      </SecondaryButton>
    </Box>
  );
};
