import {
  ExceptionService,
  ToastService,
  useGetMissedCalls,
  useMenuDrawerStoreSelectors,
} from "@/shared";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useRef, useState } from "react";
import styles from "./MissedCalls.module.scss";
import {
  ActionButtons,
  ActionSide,
  MissedCallsPdf,
  MissedCallsTable,
} from "./components";
import { pdf } from "@react-pdf/renderer";
import { handleExportToExcel } from "./helpers";

export const MissedCallsPage = () => {
  const boxRef = useRef<HTMLDivElement>(null);

  const isMenuDrawerOpen = useMenuDrawerStoreSelectors.use.isMenuDrawerOpen();

  const [isLoading, setIsLoading] = useState(false);

  const [dateFrom, setDateFrom] = useState<Dayjs | null>(dayjs(new Date()));
  const [dateTo, setDateTo] = useState<Dayjs | null>(dayjs(new Date()));

  const [boxHeight, setBoxHeight] = useState(0);

  const { calls, refetch } = useGetMissedCalls({
    date_start: dateFrom?.format("YYYY-MM-DD") || "",
    date_end: dateTo?.format("YYYY-MM-DD") || "",
  });

  const refetchCalls = async () => {
    try {
      setIsLoading(true);
      await refetch();
    } catch (error) {
      ToastService.onDanger({ title: ExceptionService.errorResolver(error) });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (boxRef.current) {
      setBoxHeight(boxRef.current.offsetHeight);
    }
  }, [boxRef]);

  const handleOpenPdfInNewTab = async () => {
    const blob = await pdf(
      <MissedCallsPdf calls={calls} dateFrom={dateFrom} dateTo={dateTo} />
    ).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  const sideContainerRef = useRef<HTMLDivElement | null>(null);
  const [mainContainerWidth, setMainContainerWidth] = useState("100vw");

  useEffect(() => {
    const updateMainContainerWidth = () => {
      const sideContainerWidth = sideContainerRef.current
        ? sideContainerRef.current.offsetWidth
        : 0;
      const drawerWidth = isMenuDrawerOpen ? 240 + 12 : 64 + 14;
      setMainContainerWidth(
        `calc(100vw - ${sideContainerWidth + drawerWidth}px)`
      );
    };

    updateMainContainerWidth();

    window.addEventListener("resize", updateMainContainerWidth);
    return () => window.removeEventListener("resize", updateMainContainerWidth);
  }, [isMenuDrawerOpen]);

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
      <div className={styles.side_container} ref={sideContainerRef}>
        <div className={styles.filter}>
          <ActionSide
            dateFrom={dateFrom}
            setDateFrom={setDateFrom}
            dateTo={dateTo}
            setDateTo={setDateTo}
            refetchCalls={refetchCalls}
            isLoading={isLoading}
          />

          <ActionButtons
            handleExportToExcel={() =>
              handleExportToExcel(calls, dateFrom, dateTo)
            }
            handleExportToPDF={handleOpenPdfInNewTab}
          />
        </div>
      </div>

      <div
        className={styles.main_container}
        style={{
          width: mainContainerWidth,
        }}
      >
        <MissedCallsTable
          calls={calls}
          boxHeight={boxHeight}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};
