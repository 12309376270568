import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { privateRoutes, publicRoutes, useAuthStoreSelectors } from "./shared";
import dayjs from "dayjs";
import "dayjs/locale/uk";
import updateLocale from "dayjs/plugin/updateLocale";

const App = () => {
  dayjs.extend(updateLocale);

  dayjs.updateLocale("uk", {
    months: [
      "Січень",
      "Лютий",
      "Березень",
      "Квітень",
      "Травень",
      "Червень",
      "Липень",
      "Серпень",
      "Вересень",
      "Жовтень",
      "Листопад",
      "Грудень",
    ],
  });

  const userId = useAuthStoreSelectors.use.user_id();

  const router = createBrowserRouter([
    userId ? privateRoutes() : {},
    !userId ? publicRoutes() : {},
  ]);

  return <RouterProvider router={router} />;
};

export default App;
