import { Colors } from "@/styles";
import styled from "@emotion/styled";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers";

export type AnyType = any;

export const formatDate = (isoString: string) => {
  const date = new Date(isoString);
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
};

export const DD_MM_YYYY_TIME = (dateString: string) => {
  const date = new Date(dateString);

  const formattedDate = date.toLocaleDateString("en-GB");

  const formattedTime = date.toLocaleTimeString("en-GB");

  return `${formattedDate} ${formattedTime}`;
};

export const DD_MM_YYYY = (dateString: Date | undefined) => {
  if (!dateString) {
    return null;
  }

  const date = new Date(dateString);

  const formattedDate = date.toLocaleDateString("en-GB");

  return formattedDate;
};

export const CustomDatePicker = styled(DatePicker)(() => ({
  "& .MuiInputBase-root": {
    fontSize: "0.750rem",
    height: "30px",
    width: "132px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "gray",
    },
    "&:hover fieldset": {
      borderColor: Colors.Primary,
      opacity: 0.5,
      transition: "all .2s ease",
    },
    "&.Mui-focused fieldset": {
      borderColor: Colors.Primary,
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.2rem",
    },
  },
}));

export const StyledDataGrid = styled(DataGrid)({
  overflowX: "auto",
  "& .MuiDataGrid-columnHeader:after": {
    display: "none",
  },
  "& .MuiDataGrid-iconSeparator": {
    visibility: "hidden",
  },
  "& .MuiDataGrid-columnHeader:last-child": {
    borderRight: "none",
  },
  "& .MuiDataGrid-cell": {
    borderRight: "1px solid #e0e0e0",
  },
  "& .MuiDataGrid-cell:last-child": {
    borderRight: "none",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-checkboxInput": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeaderCheckbox, .MuiDataGrid-cellCheckbox": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader": {
    borderRight: "1px solid #e0e0e0",
    "& .MuiDataGrid-iconButtonContainer": {
      visibility: "visible",
      width: "auto",
    },
    "& .MuiDataGrid-sortIcon": {
      opacity: 1,
    },
  },
  "& .MuiDataGrid-row": {
    "&:hover": {
      backgroundColor: Colors.Primary,
      opacity: 0.8,
      transition: "all .1s ease",
    },
    "&.Mui-selected": {
      backgroundColor: Colors.Primary,
      "&:hover": {
        backgroundColor: Colors.Primary,
        opacity: 0.8,
        transition: "all .1s ease",
      },
    },
  },
  "& .MuiDataGrid-row:nth-of-type(odd)": {
    "&:hover": {
      backgroundColor: Colors.Primary,
      opacity: 0.8,
      transition: "all .1s ease",
    },
    backgroundColor: "#f5f5f5",
    "&.Mui-selected": {
      backgroundColor: Colors.Primary,
      "&:hover": {
        backgroundColor: Colors.Primary,
        opacity: 0.8,
        transition: "all .1s ease",
      },
    },
  },
});

export const StyledDataGridCallAnalysis = styled(StyledDataGrid)({
  // "& .MuiDataGrid-row:nth-of-type(odd)": {
  //   backgroundColor: "transparent",
  // },
  // "& .MuiDataGrid-row:nth-of-type(1)": {
  //   backgroundColor: "#f5f5f5",
  // },
  // "& .MuiDataGrid-row:nth-of-type(2)": {
  //   backgroundColor: "#f7f7f7",
  // },
  // "& .MuiDataGrid-row:nth-of-type(5)": {
  //   backgroundColor: "#f7f7f7",
  // },
  // "& .MuiDataGrid-row:nth-of-type(8)": {
  //   backgroundColor: "#f5f5f5",
  // },
  // "& .MuiDataGrid-row:nth-of-type(11)": {
  //   backgroundColor: "#f5f5f5",
  // },
});
