import { DateFilter } from "./DateFilter";
import { Dayjs } from "dayjs";
import styles from "../CallsJournal.module.scss";

interface TopPanelProps {
  dateFrom: Dayjs | null;
  setDateFrom: (date: Dayjs | null) => void;
  dateTo: Dayjs | null;
  setDateTo: (date: Dayjs | null) => void;
  refetchCalls: () => void;
  isLoading: boolean;
}

export const TopPanel = ({
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
  refetchCalls,
  isLoading,
}: TopPanelProps) => {
  return (
    <div className={styles.top_panel_container}>
      <DateFilter
        dateFrom={dateFrom}
        setDateFrom={setDateFrom}
        dateTo={dateTo}
        setDateTo={setDateTo}
        refetchCalls={refetchCalls}
        isLoading={isLoading}
      />

      {/* <MakeCall /> */}
    </div>
  );
};
