import { Box, IconButton } from "@mui/material";
import { CustomInput, PrimaryButton, useSignIn } from "@/shared";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { LeftLoginSide } from "./components";

import styles from "./LoginPage.module.scss";

import PersonPinIcon from "@mui/icons-material/PersonPin";
import { useState } from "react";
import { LockOpen, Lock } from "@mui/icons-material";
import { Colors } from "@/styles";

interface FormValues {
  login: string;
  password: string;
}

export const LoginPage = () => {
  const { signIn, isPending } = useSignIn();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const { control, handleSubmit } = useForm<FormValues>({
    values: {
      login: "",
      password: "",
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async ({ login, password }) => {
    await signIn({ login, password });
  };

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.container}>
        <Box sx={{ display: "flex", height: "100%" }}>
          <LeftLoginSide />

          <Box className={styles.formContainer}>
            <Box
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <PersonPinIcon
                sx={{ fontSize: 100, color: Colors.Primary, mb: 2 }}
              />

              <Controller
                control={control}
                name="login"
                rules={{ required: "Login is required" }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <CustomInput
                      label="Login"
                      fullWidth
                      value={value}
                      onChange={onChange}
                      sx={{ width: "80%", mb: 4 }}
                      error={error ? true : false}
                      autoCapitalize="false"
                    />
                  );
                }}
              />

              <Box
                sx={{
                  width: "80%",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  mb: 4,
                }}
              >
                <Controller
                  control={control}
                  name="password"
                  rules={{ required: "Password is required" }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <CustomInput
                      type={showPassword ? "text" : "password"}
                      label="Password"
                      fullWidth
                      value={value}
                      onChange={onChange}
                      error={error ? true : false}
                      autoCapitalize="false"
                    />
                  )}
                />

                <Box sx={{ position: "absolute", top: 8, right: 20 }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <LockOpen /> : <Lock />}
                  </IconButton>
                </Box>
              </Box>

              <PrimaryButton
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                sx={{ width: "50%", backgroundColor: Colors.Primary }}
                disabled={isPending}
              >
                Log In
              </PrimaryButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
