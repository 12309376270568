import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Dayjs } from "dayjs";
import { Colors } from "@/styles";
import { Box, IconButton } from "@mui/material";
import { CustomDatePicker, SecondaryButton } from "@/shared";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import styles from "../CallsJournal.module.scss";

interface DateFilterProps {
  dateFrom: Dayjs | null;
  setDateFrom: (date: Dayjs | null) => void;
  dateTo: Dayjs | null;
  setDateTo: (date: Dayjs | null) => void;
  refetchCalls: () => void;
  isLoading: boolean;
}

export const DateFilter = ({
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  refetchCalls,
  isLoading,
}: DateFilterProps) => {
  return (
    <Box>
      <Box
        sx={{ fontSize: "13px", color: Colors.Green, fontWeight: "500", mb: 1 }}
      >
        Фільтр по даті
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div
              className={styles.date_picker}
              style={{ justifyContent: "space-between" }}
            >
              {/* <Box sx={{ fontSize: "13px" }}>З дати:</Box> */}
              <CustomDatePicker
                value={dateFrom}
                onChange={(newValue) => setDateFrom(newValue)}
                format="DD/MM/YYYY"
                slots={{
                  openPickerButton: (props) => (
                    <IconButton
                      {...props}
                      sx={{
                        backgroundColor: "transparent",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <CalendarMonthIcon sx={{ fontSize: "5px" }} />
                    </IconButton>
                  ),
                }}
                slotProps={{
                  day: {
                    sx: {
                      "&.MuiPickersDay-root.Mui-selected": {
                        backgroundColor: Colors.Primary,
                      },
                    },
                  },
                }}
              />
            </div>

            <div className={styles.date_picker}>
              {/* <Box sx={{ fontSize: "13px" }}>По дату:</Box> */}
              <CustomDatePicker
                value={dateTo}
                onChange={(newValue) => setDateTo(newValue)}
                format="DD/MM/YYYY"
                slots={{
                  openPickerButton: (props) => (
                    <IconButton
                      {...props}
                      sx={{
                        backgroundColor: "transparent",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <CalendarMonthIcon sx={{ fontSize: "5px" }} />
                    </IconButton>
                  ),
                }}
                slotProps={{
                  day: {
                    sx: {
                      "&.MuiPickersDay-root.Mui-selected": {
                        backgroundColor: Colors.Primary,
                      },
                    },
                  },
                }}
              />
            </div>
          </LocalizationProvider>
        </Box>

        <SecondaryButton
          className={styles.date_confirm_button}
          onClick={refetchCalls}
          disabled={isLoading}
        >
          <IoIosCheckmarkCircleOutline
            style={{ fontSize: "20px" }}
            color={Colors.Green}
          />
          Відбір по датам
        </SecondaryButton>
      </Box>
    </Box>
  );
};
