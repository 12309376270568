import { CallsAnalysis } from "@/shared";
import dayjs from "dayjs";
import { defaultRowsArray, isDate } from "./Helpers";

export const rows = (calls: CallsAnalysis[], selectedFields: string[]) => {
  if (!calls.length) {
    return null;
  }

  const ensureAnalyticsRow = (rows: any[]) => {
    return [...rows];
  };

  const filterRowsBySelectedFields = (rows: any[], fields: string[]) => {
    return rows.filter((row) => fields.includes(row.id));
  };

  const filteredRows = filterRowsBySelectedFields(
    defaultRowsArray,
    selectedFields
  );

  return ensureAnalyticsRow(filteredRows);
};

export const tableColumns = (calls: CallsAnalysis[]) => {
  const firstColumn = {
    field: "staticField",
    headerName: "Результат",
    width: 200,
    sortable: false,
    renderCell: ({ row }: { row: { id: string } }) => {
      switch (row.id) {
        case "answered":
          return <div>Вдалих загалом</div>;
        case "answered_in":
          return <div>Вдалих вхідних</div>;
        case "answered_in_first":
          return <div>Вдалих первинних вхідних</div>;
        case "answered_in_second":
          return <div>Вдалих повторних вхідних</div>;
        case "answered_out":
          return <div>Вдалих вихідних</div>;
        case "call_tracking":
          return <div>Колтрекінг</div>;
        case "in_first":
          return <div>Первинних вхідних</div>;
        case "in_second":
          return <div>Повторних вхідних</div>;
        case "not_answered":
          return <div>Помилкових загалом</div>;
        case "not_answered_in":
          return <div>Помилкових вхідних</div>;
        case "not_answered_in_first":
          return <div>Помилкових первинних вхідних</div>;
        case "not_answered_in_second":
          return <div>Помилкових повторних вхідних</div>;
        case "not_answered_out":
          return <div>Помилкових вихідних</div>;
        case "regular_call":
          return <div>Звичайні</div>;
        case "total_calls":
          return <div>Дзвінків загалом</div>;
        case "total_calls_in":
          return <div>Вхідних загалом</div>;
        case "total_calls_out":
          return <div>Вихідних загалом</div>;
        default:
          return null;
      }
    },
  };

  const dateColumns = calls.map((call) => ({
    field: call.date,
    renderHeader: () => {
      return isDate(call.date)
        ? dayjs(call.date).format("DD/MM/YYYY")
        : call.date;
    },
    width: 140,
    sortable: false,
    renderCell: ({ row }: { row: { id: string } }) => {
      switch (row.id) {
        case "answered":
          return call.answered;
        case "answered_in":
          return call.answered_in;
        case "answered_in_first":
          return call.answered_in_first;
        case "answered_in_second":
          return call.answered_in_second;
        case "answered_out":
          return call.answered_out;
        case "call_tracking":
          return call.call_tracking;
        case "in_first":
          return call.in_first;
        case "in_second":
          return call.in_second;
        case "not_answered":
          return call.not_answered;
        case "not_answered_in":
          return call.not_answered_in;
        case "not_answered_in_first":
          return call.not_answered_in_first;
        case "not_answered_in_second":
          return call.answered_in_second;
        case "not_answered_out":
          return call.not_answered_out;
        case "regular_call":
          return call.regular_call;
        case "total_calls":
          return call.total_calls;
        case "total_calls_in":
          return call.total_calls_in;
        case "total_calls_out":
          return call.total_calls_out;
        default:
          return null;
      }
    },
  }));

  return [firstColumn, ...dateColumns];
};
