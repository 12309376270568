import i18n from "i18next";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const errorResolver = (error: any) => {
  if (error) {
    return error?.result || i18n.t("errors.server-unable");
  }

  // if (error.request) {
  //   return error?.request?.message ?? i18n.t("errors.server-unable");
  // }

  // if (error.message) {
  //   return error.message ?? i18n.t("errors.server-unable");
  // }

  return error?.message ?? i18n.t("errors.server-unable");
};

export const ExceptionService = {
  errorResolver,
};
