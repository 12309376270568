import { CallService } from "./../CallService";
import { useAuthStoreSelectors } from "@/shared/stores";
import { useQuery } from "@tanstack/react-query";

interface GetCallsProps {
  date_start: string;
  date_end: string;
}

export const useGetCallsAnalysis = ({
  date_start,
  date_end,
}: GetCallsProps) => {
  const user_id = useAuthStoreSelectors.use.user_id();

  const { data, refetch } = useQuery({
    queryKey: ["calls_analysis"],
    queryFn: async () => {
      const response = await CallService.getCallsAnalysis({
        user_id: user_id || "",
        date_start,
        date_end,
      });

      return response?.data.calls;
    },
  });

  return {
    calls: data ?? [],
    refetch,
  };
};
