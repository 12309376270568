import { Colors } from "@/styles";
import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const SecondaryButton = styled(Button)({
  color: "#505050",
  fontSize: "14px",
  border: "1px solid #999999",
  textTransform: "none",
  "&:hover": {
    opacity: 0.9,
    transition: "all .2s ease",
    border: `1px solid ${Colors.Primary}`,
    color: Colors.Primary,
  },
});
