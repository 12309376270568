import { CallsAnalysis } from "@/shared";
import dayjs from "dayjs";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { colors, fields } from "../helpers";
import { toPng } from "html-to-image";
import { useEffect, useRef } from "react";

interface CallAnalysisDataChartProps {
  calls: CallsAnalysis[];
  selectedFields: string[];
  onCaptureImage: (imageDataUrl: string) => void;
}

export const CallAnalysisDataChart = ({
  calls,
  selectedFields,
  onCaptureImage,
}: CallAnalysisDataChartProps) => {
  const chartRef = useRef(null);

  const isDate = (dateStr: string) => {
    return (
      dayjs(dateStr, "YYYY-MM-DD", true).isValid() ||
      dayjs(dateStr, "YYYY-MM-DDTHH:mm:ss", true).isValid()
    );
  };

  useEffect(() => {
    if (chartRef.current) {
      toPng(chartRef.current)
        .then((dataUrl) => {
          onCaptureImage(dataUrl);
        })
        .catch((error) => {
          console.error("Failed to capture chart as image:", error);
        });
    }
  }, [chartRef, calls, selectedFields, onCaptureImage]);

  return (
    <ResponsiveContainer
      ref={chartRef}
      width="100%"
      height={450}
      style={{ paddingRight: 30, marginTop: 40, marginBottom: 40 }}
    >
      <LineChart data={calls}>
        <CartesianGrid strokeDasharray="10 10" />
        <XAxis
          dataKey="date"
          tickFormatter={(date) =>
            isDate(date) ? dayjs(date).format("DD/MM/YYYY") : date
          }
        />
        <YAxis />
        <Tooltip
          labelFormatter={(date) =>
            isDate(date) ? dayjs(date).format("DD/MM/YYYY") : date
          }
        />
        <Legend />

        {selectedFields.map((elem, index) => {
          const field = fields.find((f) => f.value === elem);
          return (
            <Line
              type="linear"
              dataKey={elem}
              name={field?.title || elem}
              stroke={colors[index % colors.length]}
            />
          );
        })}
        {/* <Line
          type="linear"
          dataKey="answered_in_first"
          stroke="#8884d8"
          name="Первинний"
        />
        <Line
          type="linear"
          dataKey="answered_in_second"
          stroke="#82ca9d"
          name="Повторний"
        />
        <Line
          type="linear"
          dataKey="answered"
          stroke="#ffc658"
          name="Відповіли"
        />
        <Line
          type="linear"
          dataKey="not_answered"
          stroke="#ff7300"
          name="Без відповіді"
        /> */}
      </LineChart>
    </ResponsiveContainer>
  );
};
