import { AuthLayout } from "@/modules";
import { LoginPage } from "@/pages";
import { Navigate } from "react-router-dom";

export const publicRoutes = () => {
  return {
    element: <AuthLayout />,
    children: [
      { path: "/login", element: <LoginPage /> },
      { path: "*", element: <Navigate to="/login" replace /> },
    ],
  };
};
