import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { DD_MM_YYYY, DD_MM_YYYY_TIME, MissedCall } from "@/shared";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";

Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
});

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontFamily: "Roboto",
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    fontWeight: 500,
  },
  table: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#b3b3b3",
    borderRadius: 4,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeaderRow: {
    flexDirection: "row",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 0.25,
    borderColor: "#b3b3b3",
    padding: 5,
  },
  tableCell: {
    fontSize: 10,
    padding: 1,
  },
  tableHeaderCell: {
    fontSize: 10,
    fontWeight: 500,
    padding: 1,
  },
  oddRow: {
    backgroundColor: "#f5f5f5",
  },
  evenRow: {
    backgroundColor: "#ffffff",
  },
});

interface MissedCallsPdfDocumentProps {
  calls: MissedCall[];
  dateFrom: dayjs.Dayjs | null;
  dateTo: dayjs.Dayjs | null;
}

export const MissedCallsPdf = ({
  calls,
  dateFrom,
  dateTo,
}: MissedCallsPdfDocumentProps) => {
  return (
    <Document>
      <Page style={styles.page}>
        <Text style={styles.header}>
          Дзвінки без відповіді за період з {DD_MM_YYYY(dateFrom?.toDate())} по{" "}
          {DD_MM_YYYY(dateTo?.toDate())}
        </Text>
        <View style={styles.table}>
          <View style={styles.tableHeaderRow}>
            <View style={[styles.tableCol, { width: "45%" }]}>
              <Text style={styles.tableHeaderCell}>Абонент, тел</Text>
            </View>
            <View style={[styles.tableCol, { width: "15%" }]}>
              <Text style={styles.tableHeaderCell}>Тип розмови</Text>
            </View>
            <View style={[styles.tableCol, { width: "15%" }]}>
              <Text style={styles.tableHeaderCell}>Результат</Text>
            </View>
            <View style={[styles.tableCol, { width: "25%" }]}>
              <Text style={styles.tableHeaderCell}>Період</Text>
            </View>
          </View>

          {calls.map((call, index) => (
            <View
              style={[
                styles.tableRow,
                index % 2 === 0 ? styles.evenRow : styles.oddRow,
              ]}
              key={uuidv4()}
            >
              <View style={[styles.tableCol, { width: "45%" }]}>
                <Text style={styles.tableCell}>
                  {call.caller === call.caller_code
                    ? call.caller
                    : `${call.caller}, ${call.caller_code}`}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "15%" }]}>
                <Text style={styles.tableCell}>{call.call_type}</Text>
              </View>
              <View style={[styles.tableCol, { width: "15%" }]}>
                <Text style={styles.tableCell}>{call.call_result}</Text>
              </View>
              <View style={[styles.tableCol, { width: "25%" }]}>
                <Text style={styles.tableCell}>
                  {DD_MM_YYYY_TIME(call.date)}
                </Text>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};
