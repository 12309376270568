import { Checkbox, FormControlLabel, FormGroup, Box } from "@mui/material";
import { Colors } from "@/styles";
import { CallsAnalysisDateFilter } from "./CallsAnalysisDateFilter";
import { Dayjs } from "dayjs";
import { SecondaryButton } from "@/shared";
import styles from "../CallsAnalysis.module.scss";
import { fields, handleExportToExcel, TableField } from "../helpers";
import { ActionButtons } from "./ActionButtons";

interface SelectedTableFieldsProps {
  dateFrom: Dayjs | null;
  setDateFrom: (date: Dayjs | null) => void;
  dateTo: Dayjs | null;
  setDateTo: (date: Dayjs | null) => void;
  refetchCalls: () => void;
  isLoading: boolean;
  selectedFields: string[];
  setSelectedFields: (value: string[]) => void;
  calls: any[];
  chartImage: string;
  handleOpenPdfInNewTab: () => Promise<void>;
}

export const SelectTableFields = ({
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
  refetchCalls,
  isLoading,
  selectedFields,
  setSelectedFields,
  chartImage,
  calls,
  handleOpenPdfInNewTab,
}: SelectedTableFieldsProps) => {
  const handleCheckboxChange = (field: TableField) => {
    if (selectedFields.includes(field.value)) {
      setSelectedFields(selectedFields.filter((item) => item !== field.value));
    } else {
      setSelectedFields([...selectedFields, field.value]);
    }
  };

  return (
    <>
      <Box sx={{ mb: "16px" }}>
        <Box
          sx={{
            fontSize: "13px",
            color: Colors.Green,
            fontWeight: "500",
            marginBottom: "10px",
          }}
        >
          Фільтр звіту
        </Box>

        <CallsAnalysisDateFilter
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
          dateTo={dateTo}
          setDateTo={setDateTo}
        />

        <Box sx={{ marginTop: "16px" }}>
          <SecondaryButton
            className={styles.confirm_button}
            onClick={refetchCalls}
            disabled={isLoading}
          >
            Сформувати
          </SecondaryButton>
        </Box>
      </Box>

      <ActionButtons
        handleExportToExcel={() =>
          handleExportToExcel(
            calls,
            dateFrom,
            dateTo,
            selectedFields,
            chartImage
          )
        }
        handleExportToPDF={handleOpenPdfInNewTab}
      />

      <FormGroup>
        {fields.map((field, index) => (
          <FormControlLabel
            key={index}
            style={{
              marginBottom:
                index === 2 || index === 4 || index === 9 || index === 14
                  ? 10
                  : -3,
            }}
            control={
              <Checkbox
                checked={selectedFields.includes(field.value) ? true : false}
                onChange={() => handleCheckboxChange(field)}
                name={field.value}
                size="small"
                sx={{
                  color: Colors.Primary,
                  "&.Mui-checked": {
                    color: Colors.Primary,
                  },
                }}
              />
            }
            label={<Box sx={{ fontSize: 13 }}>{field.title}</Box>}
          />
        ))}
      </FormGroup>
    </>
  );
};
