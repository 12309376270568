import { Box, IconButton, InputAdornment } from "@mui/material";
import { CustomInput } from "./Input";
import styled from "@emotion/styled";
import { IoIosClose } from "react-icons/io";
import { Colors } from "@/styles";

const SearchBar = styled(CustomInput)({
  "& .MuiOutlinedInput-root": {
    height: 35,
    fontSize: 13,
  },
  '& input[type="search"]::-webkit-search-cancel-button': {
    appearance: "none",
  },
});

interface SearchBarInputProps {
  value: string;
  onChange: (value: string) => void;
}

export const SearchBarInput = ({ value, onChange }: SearchBarInputProps) => {
  return (
    <Box>
      <SearchBar
        fullWidth
        id="outlined-search"
        placeholder="Пошук"
        type="search"
        value={value}
        onChange={(event) => onChange(event.target.value)}
        InputProps={{
          endAdornment: value && (
            <InputAdornment position="end">
              <IconButton
                onClick={() => onChange("")}
                edge="end"
                sx={{
                  backgroundColor: "transparent",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <IoIosClose
                  style={{
                    color: Colors.Primary,
                    fontSize: 30,
                  }}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
