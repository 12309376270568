import { Box } from "@mui/material";
import LoginImg from "@/assets/login-image.jpg";
import { Colors } from "@/styles";

export const LeftLoginSide = () => {
  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: Colors.Primary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={LoginImg}
        alt="login"
        style={{
          objectFit: "contain",
          width: "100%",
          height: "100%",
        }}
      />
    </Box>
  );
};
