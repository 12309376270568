// @ts-nocheck

import { Call, StyledDataGrid, useMenuDrawerStoreSelectors } from "@/shared";
import { Box, duration } from "@mui/material";
import { GridSortModel } from "@mui/x-data-grid";
import { useState } from "react";
import { FcDeleteDatabase } from "react-icons/fc";
import { tableColumns } from "../helpers";
import { TechFieldsCheckbox } from "./TechFieldsCheckbox";
import { Controller, useForm } from "react-hook-form";

interface CallsTableProps {
  calls: Call[];
  boxHeight: number;
  isLoading: boolean;
}

export const CallsTable = ({
  calls,
  boxHeight,
  isLoading,
}: CallsTableProps) => {
  const isMenuDrawerOpen = useMenuDrawerStoreSelectors.use.isMenuDrawerOpen();

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "calldate", sort: "asc" },
  ]);

  const { control, watch } = useForm({
    values: {
      isTechFields: false,
    },
  });

  const isTechFields = watch("isTechFields");

  const height = `${boxHeight + 35}px`;
  const width = window.innerWidth - (isMenuDrawerOpen ? 240 + 24 : 64 + 26);

  return (
    <>
      <Controller
        control={control}
        name="isTechFields"
        render={({ field: { value, onChange } }) => (
          <TechFieldsCheckbox checked={value} onChange={onChange} />
        )}
      />

      <Box
        sx={{
          height: `calc(100vh - ${height})`,
          width: width,
          maxWidth: width,
        }}
      >
        <StyledDataGrid
          rows={calls}
          columns={tableColumns(calls, isTechFields)}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          hideSortIcons={false}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          pageSizeOptions={[100]}
          getRowId={(call: Call) => call.uniqueid}
          rowHeight={35}
          columnHeaderHeight={35}
          disableMultipleRowSelection
          hideFooterSelectedRowCount
          checkboxSelection
          disableColumnMenu
          loading={isLoading}
          slots={{
            noRowsOverlay: () => (
              <Box
                display="flex"
                height="100%"
                alignItems="center"
                justifyContent="center"
                fontWeight="500"
                fontSize={14}
                flexDirection="column"
              >
                <FcDeleteDatabase
                  fontSize={120}
                  style={{ marginBottom: "10px" }}
                />

                <Box>На сьогодні дзвінків не зафіксовано.</Box>

                <Box>
                  Будь ласка, виберіть іншу дату для перегляду дзвінків.
                </Box>
              </Box>
            ),
          }}
        />
      </Box>
    </>
  );
};
