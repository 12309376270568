import { baseQuery } from "../baseQuery";
import {
  GetCallsAnalysis,
  GetCallsRequest,
  GetCallsResponse,
  GetMissedCallsResponse,
} from "./models";
import qs from "qs";

const getCalls = (params: GetCallsRequest) => {
  const queryString = qs.stringify({
    user_id: params.user_id,
    date_start: params.date_start,
    date_end: params.date_end,
  });

  return baseQuery.get<GetCallsResponse>(`/web_calls?${queryString}`);
};

const getMissedCalls = (params: GetCallsRequest) => {
  const queryString = qs.stringify({
    user_id: params.user_id,
    date_start: params.date_start,
    date_end: params.date_end,
  });

  return baseQuery.get<GetMissedCallsResponse>(
    `/web_missed_calls?${queryString}`
  );
};

const getCallsAnalysis = (params: GetCallsRequest) => {
  const queryString = qs.stringify({
    user_id: params.user_id,
    date_start: params.date_start,
    date_end: params.date_end,
  });

  return baseQuery.get<GetCallsAnalysis>(`/web_all_calls?${queryString}`);
};

export const CallService = {
  getCalls,
  getMissedCalls,
  getCallsAnalysis,
};
