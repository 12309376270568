interface WeekData {
  [key: string]: number | string;
  start_date: string;
  end_date: string;
}

export interface Weeks {
  [key: string]: WeekData;
}

interface MonthData {
  start_date: string;
  end_date: string;
  date: string;
  [key: string]: number | string;
}

export interface Months {
  [key: string]: MonthData;
}

export const fieldLabels: { [key: string]: string } = {
  answered: "Вдалих загалом",
  answered_in: "Вдалих вхідних",
  answered_in_first: "Вдалих первинних вхідних",
  answered_in_second: "Вдалих повторних вхідних",
  answered_out: "Вдалих вихідних",
  call_tracking: "Колтрекінг",
  in_first: "Первинних вхідних",
  in_second: "Повторних вхідних",
  not_answered: "Помилкових загалом",
  not_answered_in: "Помилкових вхідних",
  not_answered_in_first: "Помилкових первинних вхідних",
  not_answered_in_second: "Помилкових повторних вхідних",
  not_answered_out: "Помилкових вихідних",
  regular_call: "Звичайні",
  total_calls: "Дзвінків загалом",
  total_calls_in: "Вхідних загалом",
  total_calls_out: "Вихідних загалом",
};
